import $ from 'jquery'
import AutoNumeric from 'autonumeric';

$(document).on("turbolinks:load", function() {
  $('#downpayment_initial_amount[data-amount-remaining]').keyup(function() {
    const $input = $(this)
    const amountRemaining = Number($input.data('amountRemaining'))
    const amountDownpayment = $.money.parse($input.val(), $input.data('moneyFormat'))
    if(isNaN(amountRemaining) || isNaN(amountDownpayment)) return;

    const $percentage = $('#downpayment_percentage_percent', $input.closest('form'))
    $percentage.val(Math.ceil(amountDownpayment/amountRemaining*100))
  }).keyup()
})
